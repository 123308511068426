$(function() {

	// --------------------------------------------------------------------------
	// Svg Polyfill
	// --------------------------------------------------------------------------

	svg4everybody();

	// --------------------------------------------------------------------------
	// detectTouch
	// --------------------------------------------------------------------------
	
	var isTouch = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);


	// --------------------------------------------------------------------------
	// Scroll To
	// --------------------------------------------------------------------------

	$('.js-scrollto').on('click', function(event) {
		event.preventDefault();

		var href = $(this).attr('href'),
			offsetTop = $(href).offset().top;

		

	    $('html, body').animate({
		    scrollTop: offsetTop
		}, 500);
	    

	});


	// --------------------------------------------------------------------------
	// Triggers
	// --------------------------------------------------------------------------


	$('.nav').on('click', '.nav__trigger', function(event) {
		event.preventDefault();
		
		if( $('html').is('.is-nav-open') ) {
			$('html').removeClass('is-nav-open');
		} else {
			$('html').addClass('is-nav-open');
		}
	});


	$(document).on( 'click', function(event) {
		if($(event.target).closest('.nav').length==0) {
			$('html').removeClass('is-nav-open');
		}
	});


	// ----


	$('.search').on('click', '.search__trigger', function(event) {
		event.preventDefault();
		
		if( $('html').is('.is-search-open') ) {
			$('html').removeClass('is-search-open');
		} else {
			$('html').addClass('is-search-open');

			// $('.search__input').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(e) {
			//     $(this).trigger('focus')
			// });
		}
	});


	$(document).on( 'click', function(event) {
		if($(event.target).closest('.search').length==0) {
			$('html').removeClass('is-search-open');
		}
	});


	// ----


	$('.info').on('click', '.info__trigger', function(event) {
		event.preventDefault();
		
		if( $('html').is('.is-info-open') ) {
			$('html').removeClass('is-info-open');
		} else {
			$('html').addClass('is-info-open');
		}
	});


	$(document).on( 'click', function(event) {
		if($(event.target).closest('.info').length==0) {
			$('html').removeClass('is-info-open');
		}
	});



	// --------------------------------------------------------------------------
	// Slides
	// --------------------------------------------------------------------------

	var slickPrev = '<button class="slick-arrow slick-prev"><svg class="icon-arrow-left"><use xlink:href="sprites/sprite.svg#icon-arrow-left"></use></svg></button>',
      	slickNext = '<button class="slick-arrow slick-next"><svg class="icon-arrow-right"><use xlink:href="sprites/sprite.svg#icon-arrow-right"></use></svg></button>';

	$('.js-slick-slides').slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: true,
		dots: true,
		fade: false,
		infinite: true,
		nextArrow: slickNext,
	   	prevArrow: slickPrev,
	   	autoplay: true,
	   	autoplaySpeed: 5000,
	   	pauseOnHover: true,
	   	responsive: [
	   		{
	   			breakpoint: 992,
	   			settings: {
	   				slidesToShow: 3,
	   				dots: true,
	   				arrows: false
	   			}
	   		},
	   		{
	   			breakpoint: 768,
	   			settings: {
	   				slidesToShow: 2,
	   				dots: true,
	   				arrows: false
	   			}
	   		},
	   		{
	   			breakpoint: 576,
	   			settings: {
	   				slidesToShow: 1,
	   				dots: true,
	   				arrows: false
	   			}
	   		}
	   	]
	});

	// --------------------------------------------------------------------------
	// Fancybox
	// --------------------------------------------------------------------------


	var disableBodyScroll = (function () {

	    /**
	     * Private variables
	     */
	    var _selector = false,
	        _element = false,
	        _clientY;

	    /**
	     * Polyfills for Element.matches and Element.closest
	     */
	    if (!Element.prototype.matches)
	        Element.prototype.matches = Element.prototype.msMatchesSelector ||
	        Element.prototype.webkitMatchesSelector;

	    if (!Element.prototype.closest)
	        Element.prototype.closest = function (s) {
	            var ancestor = this;
	            if (!document.documentElement.contains(el)) return null;
	            do {
	                if (ancestor.matches(s)) return ancestor;
	                ancestor = ancestor.parentElement;
	            } while (ancestor !== null);
	            return el;
	        };

	    /**
	     * Prevent default unless within _selector
	     * 
	     * @param  event object event
	     * @return void
	     */
	    var preventBodyScroll = function (event) {
	        if (false === _element || !event.target.closest(_selector)) {
	            event.preventDefault();
	        }
	    };

	    /**
	     * Cache the clientY co-ordinates for
	     * comparison
	     * 
	     * @param  event object event
	     * @return void
	     */
	    var captureClientY = function (event) {
	        // only respond to a single touch
	        if (event.targetTouches.length === 1) { 
	            _clientY = event.targetTouches[0].clientY;
	        }
	    };

	    /**
	     * Detect whether the element is at the top
	     * or the bottom of their scroll and prevent
	     * the user from scrolling beyond
	     * 
	     * @param  event object event
	     * @return void
	     */
	    var preventOverscroll = function (event) {
	        // only respond to a single touch
		    if (event.targetTouches.length !== 1) {
		    	return;
		    }

		    var clientY = event.targetTouches[0].clientY - _clientY;

		    // The element at the top of its scroll,
		    // and the user scrolls down
		    if (_element.scrollTop === 0 && clientY > 0) {
		        event.preventDefault();
		    }

		    // The element at the bottom of its scroll,
		    // and the user scrolls up
			// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
			if ((_element.scrollHeight - _element.scrollTop <= _element.clientHeight) && clientY < 0) {
		        event.preventDefault();
		    }

	    };

	    /**
	     * Disable body scroll. Scrolling with the selector is
	     * allowed if a selector is porvided.
	     * 
	     * @param  boolean allow
	     * @param  string selector Selector to element to change scroll permission
	     * @return void
	     */
	    return function (allow, selector) {
	    	if (typeof selector !== "undefined") {
		        _selector = selector;
		        _element = document.querySelector(selector);
	    	}

	        if (true === allow) {
	        	if (false !== _element) {
		            _element.addEventListener('touchstart', captureClientY, false);
		            _element.addEventListener('touchmove', preventOverscroll, false);
	        	}
	            document.body.addEventListener("touchmove", preventBodyScroll, false);
	        } else {
	        	if (false !== _element) {
		            _element.removeEventListener('touchstart', captureClientY, false);
		            _element.removeEventListener('touchmove', preventOverscroll, false);
		        }
	            document.body.removeEventListener("touchmove", preventBodyScroll, false);
	        }
	    };
	}());


	$('[data-fancybox]').on('click', function(event) {
		$.fancybox.close();
	});


	$('[data-fancybox]').fancybox({
		infobar : false,
		toolbar : false,
		touch : false,
		transitionEffect : 'slide',
	    beforeShow: function( instance, current ) {
	    	$('html').addClass('is-lock');

	    	disableBodyScroll(true, '.fancybox-slide');
	    },
	    afterClose: function( instance, current ) {
	    	$('html').removeClass('is-lock');
	    	$('body').removeClass('fancybox-active compensate-for-scrollbar');
	    	disableBodyScroll(false, '.fancybox-slide');
	    }
	});


	// --------------------------------------------------------------------------
	// Phone
	// --------------------------------------------------------------------------

	$('input[type=tel]').inputmask({"mask": "+7 (999) 999-99-99", showMaskOnFocus: true, showMaskOnHover: false });

	// --------------------------------------------------------------------------
	// Validate
	// --------------------------------------------------------------------------

	$.validator.addMethod("regexp", function (value, element) {
	    return this.optional(element) || /^\+\d \(\d{3}\) \d{3}-\d{2}-\d{2}$/.test(value);
	});

	var validateErrorPlacement = function(error, element) {
		error.addClass('ui-validate');
		error.appendTo(element.parent());
	}

	var validateHighlight = function(element) {
		$(element)
			.parent().addClass("is-error").removeClass('is-success');
	}
	var validateUnhighlight = function(element) {
		$(element)
			.parent().addClass('is-success').removeClass("is-error");
	}



	$('.js-validate').each(function (index, value) { 

		$(this).validate({
			errorElement: "span",
			ignore: ":disabled,:hidden",
			highlight: validateHighlight,
    		unhighlight: validateUnhighlight,
			rules: {
				fullname: "required",
				tel: {
	                required: true,
	                regexp: true
				},
				email: {
					required: true,
      				email: true
				},
				message: "required",
				city: "required",
				index: "required",
				address: "required",
				password: "required",
			    passwordconfirm: {
			    	required: true,
			    	equalTo: "#password"
			    },
			    accept: "required"

			},
			messages: {
				fullname: 'Вы не ввели ФИО',
				tel: 'Вы не ввели номер телефона',
				email: 'Введите email',
				city: 'Введите город',
				index: 'Введите индекс',
				address: 'Введите адрес',
				message: 'Введите сообщение',
				password: 'Введите пароль',
				passwordconfirm: {
					required: 'Повторите пароль',
					equalTo: 'Пароли не совпадают'
				},
				accept: 'Подтвердите условия'
			},
			errorPlacement: validateErrorPlacement,
			submitHandler: function(form) {

				$(form).addClass('is-success');


				setTimeout(function() {

					$(form).removeClass('is-success');

				}, 2000);

				// $.ajax({
				// 	type: "POST",
				// 	url: 'phpmailer/send.php',
				// 	data: $(form).serialize(),
				// 	success: function(form) {


				// 	},
				// 	error: function(form) {



				// 	}
				// });


				event.preventDefault();


			}
		});

	});



	// --------------------------------------------------------------------------
	// Preload
	// --------------------------------------------------------------------------

	$(window).on('load', function(event) {

		$('html').addClass('is-loaded');
		
	});



});


